import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout } from "../Layout";
import styled from "styled-components";
import { GridColumn } from "notes";
import { VideoContainer, VideoInner } from "../Preview";

export const PostShow = ({ event }) => {
  return (
    <Layout
      videoContent={
        <VideoContainer>
          <VideoInner>
            <ThankYou>
              <h2>{event.postShow?.thankYouHeader}</h2>
              <p>{event.postShow?.thankYouBody}</p>
            </ThankYou>
          </VideoInner>
        </VideoContainer>
      }
      banner={event.postShow?.banner}
    >
      {ReactHtmlParser(event.postShow?.body)}
    </Layout>
  );
};

const ThankYou = styled(GridColumn)`
  justify-content: center;
  h2 {
    color: ${(props) => props.theme.colors.subHeading};
    ${(props) => props.theme.fonts.header};
    font-size: 120px;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: 1.5px;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    color: #fff;
    ${(props) => props.theme.fonts.default};
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    padding: 0 24px;
    text-align: center;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    h2 {
      font-size: 50px;
      line-height: 50px;
    }
    p {
      font-size: 18px;
      line-height: 22px;
      padding: 0 12px;
    }
  }
`;
