import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import { TimeContext, Countdown } from "Components";
import { Layout } from "../Layout";
import { VideoContainer, VideoInner } from "../Preview";

export const PreShow = ({ event }) => {
  const { time } = useContext(TimeContext);
  const timeToStart =
    time < event.start &&
    Duration.fromMillis(event.start - time).shiftTo(
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      banner={event.preShow?.heading}
      videoContent={
        <VideoContainer>
          <VideoInner>
            <Countdown {...timer} label={event.preShow?.countdownLabel} />
          </VideoInner>
        </VideoContainer>
      }
    >
      {ReactHtmlParser(event.preShow?.body)}
    </Layout>
  );
};
