import Joi from "@hapi/joi";
import _ from "lodash";

export const handleErrors = (fields, formData) => {
  let dynamicSchema = {};
  fields
    .filter(({ required }) => required)
    .forEach((field) => {
      switch (field.type) {
        case "double":
          return (dynamicSchema[field.name] = Joi.object({
            [field.names[0]]: Joi.string().required(),
            [field.names[1]]: Joi.string().required(),
          }));
        case "email":
          return (dynamicSchema[field.name] = Joi.string()
            .email({ tlds: false })
            .required());
        case "phone":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "radio":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "textarea":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "text":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "zip":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "select":
          return (dynamicSchema[field.name] = Joi.string().required());
        case "optIn":
          return (dynamicSchema[field.name] = Joi.boolean().invalid(false));
        default:
          break;
      }
    });

  let combinedSchema = Joi.object({
    ...dynamicSchema,
  });

  let { error } = combinedSchema.validate(formData, {
    abortEarly: false,
    allowUnknown: true,
  });

  let errorObject = {};
  _.get(error, "details", []).map(({ path }) => {
    return (errorObject[path[0]] = path[1]
      ? { ...errorObject[path[0]], [path[1]]: true }
      : true);
  });

  return Object.keys(errorObject).length > 0 ? errorObject : null;
};
