import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { GridColumn, GridRow, withDefaultMedia } from "notes";
import { ReactComponent as BrandLogo } from "Images/brand-logo.svg";

export const Layout = withDefaultMedia(
  ({
    matches,
    children,
    videoContent,
    banner,
    footerText,
    noPadding,
    ...props
  }) => {
    return (
      <Wrapper {...props}>
        <Header xStart yCenter>
          <GridRow>
            <Logo />
          </GridRow>
        </Header>
        {videoContent && videoContent}
        {banner && (
          <VideoBanner xCenter yCenter>
            <Container xCenter yCenter>
              <Title>{banner}</Title>
            </Container>
          </VideoBanner>
        )}
        <Body noPadding={noPadding} xCenter yCenter stretch>
          {children}
        </Body>
        <Footer hasText={!!footerText}>
          {!!footerText ? ReactHtmlParser(footerText) : <FooterLogo />}
        </Footer>
      </Wrapper>
    );
  }
);

const Wrapper = styled(GridColumn)`
  background: #fff;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-height: 100vh;
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    box-shadow: none;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: center;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: 1400px;
    width: 100%;
    min-height: 961px;
  }
`;

const Container = styled(GridRow)`
  height: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  width: 100%;
  justify-content: center;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Header = styled(Container)`
  background-color: ${(props) => props.theme.colors.headerFill};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
  justify-content: left;
  height: 80px;
  z-index: 5;
  @media only screen and ${(props) => props.theme.media.mobile} {
    justify-content: center;
    height: 60px;
  }
`;

const Logo = styled(BrandLogo)`
  margin: 0;
  height: 100%;
  width: 200px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: 136px;
  }
`;

const VideoBanner = styled(GridRow)`
  background-color: ${(props) => props.theme.colors.videoBanner};
  min-height: 106px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.mobile} {
    min-height: 0;
    padding: 24px 0;
  }
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.videoBannerText};
  ${(props) => props.theme.fonts.header};
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 1.5px;
  font-weight: 700;
  position: relative;
  z-index: 5;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 6vw;
    line-height: 6vw;
    letter-spacing: 0px;
  }
`;

const Body = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.bodyFill};
  color: ${(props) => props.theme.colors.bodyText};
  ${(props) => props.theme.fonts.default};
  min-height: 200px;
  padding: ${(props) => (props.noPadding ? 0 : "30px 40px 40px 40px")};
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: ${(props) => (props.noPadding ? 0 : "30px 24px 40px 24px")};
  }
`;

const Footer = styled(GridColumn)`
  background-color: ${(props) =>
    props.hasText ? "#ffffff" : props.theme.colors.footerFill};
  color: ${(props) => props.theme.colors.footerText};
  font-family: "Overpass", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
  min-height: 240px;
  padding: 30px 32px;
  justify-content: center;
  align-items: ${(props) => (props.hasText ? "flex-start" : "center")};
  width: 100%;
  a {
    color: ${(props) => props.theme.colors.linkDefault};
    &:hover {
      color: ${(props) => props.theme.colors.linkHover};
    }
  }
  p:last-child {
    margin-top: 16px;
  }
`;

const FooterLogo = styled(BrandLogo)`
  height: 100%;
  width: 100%;
  max-width: 373px;
`;
