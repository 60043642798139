import React, { memo } from "react";
import { PhoneField } from "./Phone";
import { RadioField } from "./Radio";
import { TextAreaField } from "./TextArea";
import { DoubleField } from "./DoubleField";
import { EmailField } from "./Email";
import { ZipCode } from "./ZipCode";
import { OptIn } from "./OptIn";
import { Select } from "./Select";
import { TextField } from "./Text";

export const Field = memo(({ field, value, error, onChange }) => {
  switch (field.type) {
    case "select":
      return (
        <Select field={field} error={error} value={value} onChange={onChange} />
      );
    case "optIn":
      return (
        <OptIn field={field} error={error} value={value} onChange={onChange} />
      );
    case "zip":
      return (
        <ZipCode
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "double":
      return (
        <DoubleField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "email":
      return (
        <EmailField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "phone":
      return (
        <PhoneField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "radio":
      return (
        <RadioField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "textarea":
      return (
        <TextAreaField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    case "text":
      return (
        <TextField
          field={field}
          error={error}
          value={value}
          onChange={onChange}
        />
      );
    default:
      break;
  }
});
