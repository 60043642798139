import React, { useState, useReducer } from "react";
import styled from "styled-components";
import {
  GridColumn,
  Label,
  ButtonPrimary,
  withDefaultMedia,
  H2,
  P,
  GridRow,
} from "notes";
import { Field, handleErrors, firebase, FormMessage } from "Components";
import { reducer } from "Modules";
import { Layout } from "../Layout";
import MobilePhoto from "Images/artist-image-mobile.jpg";
import SidePhoto from "Images/artist-image.jpg";
import { fireAnalytics } from "services";

export const Register = React.memo(({ event, onSuccess }) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const fields = event?.register?.fields;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration);

    if (!errorObject) {
      if (registration.AGE_CONFIRM === "Yes") {
        setSubmitting(true);
        setErrors({});
        try {
          const submitForm = firebase.functions().httpsCallable("leads-submit");
          const result = await submitForm({
            form: {
              ...registration,
            },
            email: registration.EMAIL,
            form_id: process.env.REACT_APP_FORM_ID,
          });
          onSuccess();
          setSubmitting(false);
          fireAnalytics("form_submit", {
            event_category: event.artist,
            event_label: event.title,
            formId: process.env.REACT_APP_FORM_ID,
            setLiveEventId: process.env.REACT_APP_EVENT_ID,
          });
        } catch (error) {
          setSubmitting(false);
        }
      } else {
        setErrors({ AGE_GATE: true });
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <Layout noPadding footerText={event.register?.footer}>
      <ColumnContainer>
        <Left>
          <Container style={{ textAlign: "left" }}>
            <Heading>{event.register?.heading}</Heading>
            <Body>{event.register?.body}</Body>
            <FormTitle>{event.register?.formHeading}</FormTitle>
            <FormMessage
              active={!!errors?.AGE_GATE}
              content="You must be at least at least 18 years of age (19+ in AL &amp; NE) to register for this event."
            />
            <Questions>
              {!!fields &&
                fields
                  .sort((a, b) => a.order - b.order)
                  .map((field) => {
                    return (
                      <QuestionContainer
                        fullSize={[
                          "EMAIL",
                          "OPTIN",
                          "PROVIDER",
                          "AGE_CONFIRM",
                          "CONTACT",
                        ].includes(field.name)}
                        key={field.id}
                      >
                        {field.label && field.type !== "optIn" && (
                          <Label>{field.label}</Label>
                        )}
                        <Field
                          field={field}
                          value={registration[field.name]}
                          onChange={(value) => handleChange(value, field.name)}
                          error={{
                            status: errors[field.name],
                            message:
                              "This is a required field and cannot be blank.",
                          }}
                        />
                      </QuestionContainer>
                    );
                  })}
            </Questions>
            <ButtonContainer>
              {submitting ? (
                <RegisterButton disabled>Loading</RegisterButton>
              ) : (
                <RegisterButton
                  disabled={event.register.disableForm}
                  onClick={() => handleSubmit()}
                >
                  {event.register.disableForm
                    ? "Registration Closed"
                    : "Register"}
                </RegisterButton>
              )}
            </ButtonContainer>
          </Container>
        </Left>
        <Right>
          <SwitchedImage />
        </Right>
      </ColumnContainer>
    </Layout>
  );
});

const SwitchedImage = withDefaultMedia(({ matches }) => (
  <ResponsiveImage src={matches.small ? MobilePhoto : SidePhoto} />
));

const ResponsiveImage = styled.img`
  display: block;
  width: 100%;
`;

const Left = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.registerFill};
  padding: 0 24px;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: 0 40px;
    width: 46%;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    padding: 0 40px;
    width: 46%;
  }
`;

const Right = styled(GridColumn)`
  background: #000;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    width: 54%;
    flex-shrink: 0;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  padding: 24px 0 40px 0;
  max-width: 500px;
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 535px;
    padding: 40px 0 40px 0;
  }
`;

const FormTitle = styled(H2)`
  color: ${(props) => props.theme.colors.registerBody};
  ${(props) => props.theme.fonts.default};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
  text-align: left;
  text-transform: unset;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.571vw;
    line-height: 1.786vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 22px;
    line-height: 25px;
  }
`;

const Questions = styled(GridRow)`
  flex-wrap: wrap;
  margin-left: -3px;
  margin-right: -3px;
`;

const QuestionContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: 8px 3px 0 3px;

  & > ${Label} {
    color: ${(props) => props.theme.colors.labelColor};
    font-weight: 300;
    margin-top: 16px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin-top: 8px;
`;

export const Heading = styled(H2)`
  color: ${(props) => props.theme.colors.registerBody};
  font-size: 41px;
  line-height: 51px;
  letter-spacing: 1px;
  margin-bottom: 24px;
  text-align: left;
  text-transform: inherit;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 6.933vw;
    line-height: 8.533vw;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 3vw;
    line-height: 3.6vw;
    text-align: left;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 44px;
    line-height: 52px;
  }
`;

const Body = styled(P)`
  color: ${(props) => props.theme.colors.registerBody};
  ${(props) => props.theme.fonts.default};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: left;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.286vw;
    line-height: 1.571vw;
    text-align: left;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 18px;
    line-height: 22px;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.colors.buttonFill};
  align-items: center;
  ${(props) => props.theme.fonts.header};
  font-size: 18px;
  font-weight: inherit;
  height: 60px;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.786vw;
    height: 6vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: 25px;
    height: 84px;
  }
`;
