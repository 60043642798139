import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Checkbox, Label } from "notes";
import { FieldMessage } from "Components";

export const OptIn = ({ onChange, value, field, error = {}, ...props }) => {
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <CheckboxStyling
        title={<Formatting>{ReactHtmlParser(field.label)}</Formatting>}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const Formatting = styled(Label).attrs({ as: "div" })`
  color: ${(props) => props.theme.colors.labelColor};
  a {
    color: ${(props) => props.theme.colors.action};
    font-weight: 600;
    text-decoration: none;
  }
`;

const CheckboxStyling = styled(Checkbox)`
  margin-top: 16px;
  padding-top: 0;
  input + div {
    background: #fff;
    border: none;
    transform: none;
    top: 4px;
    svg {
      path {
        fill: #222;
      }
    }
  }
`;
