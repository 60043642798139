import React from "react";
import styled from "styled-components";
import { Select as Select2 } from "notes";
import { FieldMessage } from "Components";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected = value && field?.options.find(({ id }) => id.includes(value));
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <StyledSelect
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        onChange={(val) => handleChange(val.id)}
        style={{ cursor: "default" }}
        error={!!error.status}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const StyledSelect = styled(Select2)`
  span {
    font-weight: 400;
  }
`;
