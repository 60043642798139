import { MAXTheme } from "notes";

export const theme = {
  ...MAXTheme,
  colors: {
    ...MAXTheme.colors,
    headerFill: "#ffffff",
    footerFill: "#EDF0F2",
    videoBanner: "#0065A4",
    videoBannerText: "#ffffff",
    videoFill: "#1e1e1e",
    primary: "#2D96CD",
    action: "#00A9E0",
    actionLight: "#D9595D",
    buttonPrimaryBgHover: "#AF2430",
    labelColor: "#ffffff",
    linkDefault: "#0065A4",
    linkHover: "#03446C",
    // checkboxDisabledCheckedFill: "#9E1116",
    bodyFill: "#ffffff",
    registerFill: "#0065A4",
    registerBody: "#ffffff",
    bodyText: "#97999B",
    border: "#00A9E0",
    countdownLabel: "#04A6E1",
    subHeading: "#0065A4",
    buttonFill: "#EE3040",
    footerText: "#A2A2A2",
  },
  media: {
    ...MAXTheme.media,
    mobile: "(max-width: 600px)",
    small: "(max-width: 1063px)",
    medium: "(min-width: 1064px)",
    large: "(min-width: 1400px)",
  },
  fonts: {
    ...MAXTheme.fonts,
    header: "font-family: 'Stag-USCC-Medium', sans-serif;",
    default: "font-family: 'Stag-Sans-Medium', sans-serif;",
  },
};
