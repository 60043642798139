import React from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { Input, FlexBox, Icon } from "notes";

const Container = styled(FlexBox)`
  position: relative;
`;

export const CountryPhone = ({
  phoneNumber: value,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <Container>
      <InputMask
        maskChar=""
        mask="(999) 999-9999"
        value={value}
        onChange={onChange}
      >
        {() => (
          <Input
            type="tel"
            data-javelin-name={`input-phone`}
            data-javelin-meta={JSON.stringify(value)}
            leftIcon={<Icon form name="Phone" />}
            placeholder={placeholder}
            {...props}
          />
        )}
      </InputMask>
    </Container>
  );
};
