import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import Vimeo from "@u-wave/react-vimeo";
import { Layout } from "../Layout";
import { H2 } from "notes";

export const Preview = ({ event }) => {
  return (
    <Layout
      videoContent={
        <VideoContainer>
          <VideoInner>
            <Vimeo
              className="vimeo"
              key={event.preview?.videoId}
              video={event.preview?.videoId}
              autoplay
              responsive
            />
          </VideoInner>
        </VideoContainer>
      }
      banner={event.preview?.heading}
    >
      <Heading>{event.preview?.subHeading}</Heading>
      {ReactHtmlParser(event.preview?.body)}
    </Layout>
  );
};

export const VideoContainer = styled.div`
  background-color: ${(props) => props.theme.colors.videoFill};
  display: flex;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

export const VideoInner = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0%;
  right: 0px;
  bottom: 0;
  left: 0px;
  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export const Heading = styled(H2)`
  color: ${(props) => props.theme.colors.subHeading};
  font-size: 3vw;
  line-height: 4.793vw;
  letter-spacing: 1px;
  margin-bottom: 24px;
  text-align: center;
  text-transform: inherit;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 40px;
    line-height: 51px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
