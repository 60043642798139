import React from "react";
import ReactHtmlParser from "react-html-parser";
import Vimeo from "@u-wave/react-vimeo";
import { Layout } from "../Layout";
import { VideoInner, VideoContainer } from "../Preview";

export const Show = ({ event }) => {
  return (
    <Layout
      videoContent={
        <VideoContainer>
          <VideoInner>
            <Vimeo
              className="vimeo"
              key={event?.videoId}
              video={event?.videoId}
              autoplay
              responsive
            />
          </VideoInner>
        </VideoContainer>
      }
      banner={event.show?.heading}
    >
      {ReactHtmlParser(event.show?.body)}
    </Layout>
  );
};
